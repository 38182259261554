@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #646cff;
  color: #fff;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #535bf2;
}

html {
  scroll-behavior: smooth;
}

.mandatory-field::after {
  content: ' *';
  color: red;
}

.common-shadow {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #646cff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.background-auth {
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/background.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  zoom: 1.2;
}

.sidebar {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;
  height: 100vh;
  width: 16rem;
  background-color: white;
  border-right: 1px solid #e0e0e0;
}

.content {
  min-height: calc(100vh - 4rem);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.content-conversations {
  height: calc(100vh - 4rem);
  width: 100%;
  display: flex;
  justify-content: center;
}

.one-line-display {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
